.App-Link {
  text-decoration: underline;
  cursor: pointer;
  color: #61dafb;
  font-size: 2vw;
}

.App-Page {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  color: white;
}
