.Deck {
  width: 6vw;
  height: 17vh;
  background-color: white;
  color: black;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}

.Deck__Card-Count {
  font-weight: 400;
}
