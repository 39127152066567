.Chat {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5vw;
  padding: 0.5rem;
}

.Show-Grid {
  border: dashed white 0.2rem !important;
}

.Grid {
  display: grid;
  gap: 1rem;
  width: 95vw;
  grid-template-columns: repeat(12, minmax(4rem, 1fr));
  grid-template-areas:
    "inbox inbox header header header header header header header header timer timer"
    "main main main main main main main main main sidebar sidebar sidebar"
    "discard-pile discard-pile hand hand hand hand hand hand hand-actions hand-actions deck deck";
}

.Grid-Finished {
  display: grid;
  gap: 1rem;
  width: 95vw;
  grid-template-columns: repeat(12, minmax(4rem, 1fr));
  grid-template-areas:
    "inbox inbox header header header header header header header header timer timer"
    "main main main main main main main main main sidebar sidebar sidebar"
    ". . status status status status status status status status . .";
}

.Grid__Status {
  grid-area: status;
  font-size: 15rem;
  text-align: center;
}

.Grid__Header {
  grid-area: header;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Grid__Main {
  grid-area: main;
  min-height: 50vh;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15vw, 1fr));
  align-items: center;
  border: solid white 0.2rem;
}

.Grid__Inbox {
  grid-area: inbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Grid__Timer {
  grid-area: timer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Grid__Main__Player {
  height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5vw;
}

.Grid__Main__Player__Selected {
  border: solid #61dafb 5px;
}

.Grid__Main__Player__Hovered {
  border: solid #61dafb 5px;
}

.Grid__Sidebar {
  grid-area: sidebar;
  text-align: center;
}

.Grid__Discard-Pile {
  grid-area: discard-pile;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.Grid__Hand {
  grid-area: hand;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.Grid__Hand-Actions {
  grid-area: hand-actions;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.Grid__Hand-Actions__Button {
  width: 10vw;
  border: 2px solid;
  border-radius: 1rem;
  font-size: large;
  padding: 0.5rem;
  margin-top: 1vh;
}

.Grid__Deck {
  grid-area: deck;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.ReadyToggle {
  margin-top: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.5rem;
  border: solid 1px black;
}

.ReadyToggle-Ready {
  font-size: 1.5vw;
}

.ReadyToggle-NotReady {
  font-size: 1vw;
}

.PlayerName {
  display: flex;
  align-items: center;
  font-size: 2vw;
}

.Host-Icon {
  color: #61dafb;
}

.Current-Player-Icon {
  color: yellow;
}

.Game-Button {
  font-weight: bold;
  cursor: pointer;
}

.Grid__Hand-Actions__Title {
  margin-bottom: 1vh;
}

.Player-Status-Not-Finished {
  margin-top: 1rem;
  color: red;
}

.Player-Status-Finished {
  margin-top: 1rem;
  color: green;
}
