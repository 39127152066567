.CoworkerSelector {
  display: flex;
  align-items: center;
}

.SelectButton {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
