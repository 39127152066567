.Inbox-Button {
  border: solid white 0.2rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: medium;
  color: white;
  background: none;
  width: 75%;
  height: 3rem;
}

.Inbox-Button-Tooltip {
  font-size: 1.3rem !important;
  padding: 1rem !important;
}

.Email-Icon {
  margin-right: 0.5rem;
}
