.Dashboard-Icon {
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin-top: 10px;
}

.Dashboard-Input {
  background: 0;
  border: 0;
  color: white;
  caret-color: white;
  cursor: pointer;
  border-bottom: solid 3px white;
  margin-left: 1rem;
}

.Dashboard-Input:focus {
  outline: 0;
}

.Name-Input {
  font-weight: bold;
  font-size: 3vw;
}

.Join-Game-Input {
  font-size: 3vw;
}

.Welcome-Text {
  display: flex;
  font-size: 3vw;
  margin-right: 1rem;
  font-weight: bold;
}

.Join-Game {
  display: flex;
  font-size: 3vw;
}

.Join-Button {
  background-color: #61dafb;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 3vw;
  margin-left: 2vw;
}

.Create-Game-Link {
  margin: 4rem 0 2rem;
}
