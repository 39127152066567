.Inbox {
  display: grid;
  grid-template-columns: repeat(5, minmax(4rem, 1fr));
  grid-template-areas:
    "header header header header header"
    "left-pane main main main main";
}

.Inbox__Header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px black;
  font-size: 2vw;
}

.Inbox__Left-Pane {
  grid-area: left-pane;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 2px black;
  height: 85vh;
  overflow-y: overlay;
  margin-right: 1rem;
  overflow-x: hidden;
}

.Inbox__Main {
  grid-area: main;
  display: flex;
  flex-direction: column;
}

.Inbox__Left-Pane::-webkit-scrollbar {
  width: 0.8rem;
}

.Inbox__Left-Pane::-webkit-scrollbar-track {
  border-radius: 2rem;
  background: none;
}

.Inbox__Left-Pane::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2rem;
}

.Inbox__Left-Pane::-webkit-scrollbar-thumb:hover {
  background: #555;
}
