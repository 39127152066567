.Inbox-Main__Header {
  height: 6rem;
  margin-top: 1rem;
}

.Inbox-Main__Header__Subject {
  font-size: 1vw;
  font-weight: bold;
}

.Inbox-Main__Header__From {
  font-size: 0.8vw;
}

.Inbox-Main__Header__To {
  font-style: italic;
  color: darkgray;
}

.Inbox-Main__Body {
  font-size: 1vw;
}

.Inbox-Main__Body__Title {
  font-weight: bold;
}

.Inbox-Empty {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
}
