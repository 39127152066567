.Header {
  display: flex;
  flex-direction: column;
}

.Header__Top {
  display: flex;
  align-items: center;
}

.Header__Bottom {
  font-size: 1vw;
  text-align: center;
  font-weight: 350;
}

.Status {
  border: 2px solid;
  border-radius: 1rem;
  font-size: large;
  padding: 0.5rem;
  margin-left: 0.5rem;
}
