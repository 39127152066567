.Inbox-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid 2px black;
  min-height: 4rem;
  width: 100%;
  cursor: pointer;
  padding-left: 1rem;
}

.Inbox-Item__Subject {
  font-weight: bold;
}

.Inbox-Item__Sender {
  font-style: italic;
}

.Inbox-Item__Selected {
  background-color: #61dafb;
}
