.Card {
  width: 9vw;
  height: 24vh;
  background-color: white;
  color: black;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  cursor: pointer;
  border: 0;
}

.Card__Selected {
  border: solid 5px #61dafb;
}

.Card__Hovered {
  border: solid 5px #61dafb;
}

.Card__Description {
  font-weight: bold;
  margin-bottom: 1rem;
}

.Card__Type {
  display: flex;
}

.Card__Type__HelpIcon {
  margin-left: 0.2rem;
}
